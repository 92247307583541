import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  CardMedia,
  Hidden,
  ExpansionPanel as Accordion,
  ExpansionPanelDetails as AccordionDetails,
  ExpansionPanelSummary as AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Guide = () => {
  const [guide, setGuide] = React.useState("requirements");

  return (
    <Container maxWidth="lg">
      {/* web view */}
      <Hidden smDown>
        <Box m={3} mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <Box
                display="flex"
                flexDirection="column"
                height={"calc(100vh-150px)"}
                position="absolute"
              >
                <Typography color="textPrimary" variant="h3">
                  REQUIREMENTS
                </Typography>

                <Box m={2} mb={3} style={style.pointer}>
                  <Typography
                    color={
                      guide === "requirements" ? "textPrimary" : "textSecondary"
                    }
                    variant="h6"
                    onClick={() => setGuide("requirements")}
                  >
                    INDIVIDUAL & CORPORATION
                  </Typography>
                </Box>

                <Typography color="textPrimary" variant="h3">
                  HOW TO:
                </Typography>

                <Box m={2} style={style.pointer}>
                  <Typography
                    color={
                      guide === "register-online"
                        ? "textPrimary"
                        : "textSecondary"
                    }
                    variant="h6"
                    onClick={() => setGuide("register-online")}
                  >
                    CREATE AN ACCOUNT
                  </Typography>
                </Box>

                <Box m={2} style={style.pointer}>
                  <Typography
                    color={
                      guide === "register-offline"
                        ? "textPrimary"
                        : "textSecondary"
                    }
                    variant="h6"
                    onClick={() => setGuide("register-offline")}
                  >
                    REGISTER OFFLINE
                  </Typography>
                </Box>

                <Box m={2} style={style.pointer}>
                  <Typography
                    color={
                      guide === "payment-online"
                        ? "textPrimary"
                        : "textSecondary"
                    }
                    variant="h6"
                    onClick={() => setGuide("payment-online")}
                  >
                    MAKE PAYMENT ONLINE
                  </Typography>
                </Box>

                {/* <Box m={2} style={style.pointer}>
                                    <Typography
                                        color={ guide === 'send-email' ? 'textPrimary' : 'textSecondary'} 
                                        variant="h6"    
                                        onClick = { () => setGuide('send-email')}
                                    >
                                        SEND REQUIREMENTS VIA EMAIL
                                    </Typography>
                                </Box>    */}
              </Box>
            </Grid>

            <Grid item xs={12} sm={7} md={8} lg={9}>
              <Box p={2}>
                {guide === "register-online" && (
                  <CardMedia
                    style={style.rounded}
                    component="img"
                    src="/guide/register_online.png"
                    alt="ERPT Online registration"
                  />
                )}

                {guide === "register-offline" && (
                  <CardMedia
                    style={style.rounded}
                    component="img"
                    src="/guide/register_offline.png"
                    alt="ERPT Offline registration"
                  />
                )}

                {guide === "payment-online" && (
                  <CardMedia
                    style={style.rounded}
                    component="img"
                    src="/guide/payment_online.png"
                    alt="ERPT Online Payment"
                  />
                )}

                {guide === "send-email" && (
                  <CardMedia
                    style={style.rounded}
                    component="img"
                    src="/guide/send_email.png"
                    alt="ERPT Send Requirements Online"
                  />
                )}

                {guide === "requirements" && (
                  <CardMedia
                    style={style.rounded}
                    component="img"
                    src="/guide/requirements.png"
                    alt="ERPT Requirements"
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      {/* mobile view */}
      <Hidden mdUp>
        <Box m={2} mt={5}>
          <Box mb={2}>
            <Typography color="textPrimary" variant="h3">
              REQUIREMENTS
            </Typography>
          </Box>

          <Box mb={3}>
            <Accordion
              expanded={guide === "requirements"}
              onChange={() =>
                setGuide(guide === "requirements" ? "" : "requirements")
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">INDIVIDUAL & CORPORATION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {guide === "requirements" && (
                    <CardMedia
                      style={style.rounded}
                      component="img"
                      src="/guide/requirements.png"
                      alt="ERPT Requirements"
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb={2}>
            <Typography color="textPrimary" variant="h3">
              HOW TO:
            </Typography>
          </Box>
          <Box>
            <Accordion
              expanded={guide === "register-online"}
              onChange={() =>
                setGuide(guide === "register-online" ? "" : "register-online")
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6"> CREATE AN ACCOUNT </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {guide === "register-online" && (
                    <CardMedia
                      style={style.rounded}
                      component="img"
                      src="/guide/register_online.png"
                      alt="ERPT Online registration"
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={guide === "register-offline"}
              onChange={() =>
                setGuide(guide === "register-offline" ? "" : "register-offline")
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">REGISTER OFFLINE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {guide === "register-offline" && (
                    <CardMedia
                      style={style.rounded}
                      component="img"
                      src="/guide/register_offline.png"
                      alt="ERPT Offline registration"
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={guide === "payment-online"}
              onChange={() =>
                setGuide(guide === "payment-online" ? "" : "payment-online")
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">MAKE PAYMENT ONLINE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {guide === "payment-online" && (
                    <CardMedia
                      style={style.rounded}
                      component="img"
                      src="/guide/payment_online.png"
                      alt="ERPT Online Payment"
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
              expanded={guide === "send-email"}
              onChange={() =>
                setGuide(guide === "send-email" ? "" : "send-email")
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  SEND REQUIREMENTS VIA EMAIL
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {guide === "send-email" && (
                    <CardMedia
                      style={style.rounded}
                      component="img"
                      src="/guide/send_email.png"
                      alt="ERPT Online registration"
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion> */}
          </Box>
        </Box>
      </Hidden>
    </Container>
  );
};

export default Guide;

const style = {
  pointer: {
    cursor: "pointer",
  },
  rounded: {
    borderTopRightRadius: "20px",
    borderBottomLeftRadius: "20px",
  },
};
