/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import DocsLayout from "src/layouts/DocsLayout";
import MainLayout from "src/layouts/MainLayout";
import HomeView from "src/views/pages/HomeView";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/Guard/AuthGuard";
import GuestGuard from "src/components/Guard/GuestGuard";
import Guide from "./views/pages/HomeView/Guide";
import AdminGuard from "./components/Guard/AdminGuard";
import LCRSGuard from "./components/Guard/LCRSGuard";
import ApproverGuard from "./components/Guard/ApproverGuard";
import ReviewerGuard from "./components/Guard/ReviewerGuard";
import TreasuryGuard from "./components/Guard/TreasuryGuard";
import UserInHouseGuard from "./components/Guard/UserInHouseGuard";
import RegistratorGuard from "./components/Guard/RegistratorGuard";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/home" />,
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/pages/Error404View")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    path: "/login-unprotected",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: "/register-unprotected",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/recover/password",
    component: lazy(() => import("src/views/auth/RecoverPasswordView")),
  },

  {
    exact: true,
    guard: GuestGuard,
    path: "/recover/password/reset-form/:email/:token",
    component: lazy(() =>
      import("src/views/auth/RecoverPasswordView/NewPasswordView/")
    ),
  },

  {
    exact: true,
    guard: GuestGuard,
    path: "/mobile/app/printable/form/:type/:status_id/:accessToken",
    component: lazy(() => import("src/views/mobile-printlayout")),
  },

  // user
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        exact: true,
        path: "/app/property/register",
        component: lazy(() =>
          import("src/views/reports/DashboardView/Property/index")
        ),
      },
      {
        exact: true,
        path: "/app/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
      {
        exact: true,
        path: "/app/reports/dashboard",
        component: lazy(() => import("src/views/reports/DashboardView")),
      },
      {
        exact: true,
        path: "/app/reports/dashboard/property/holdings",
        component: lazy(() =>
          import("src/views/reports/DashboardView/Property/holdings")
        ),
      },
      {
        exact: true,
        path: "/app/reports/dashboard/property/history",
        component: lazy(() =>
          import("src/views/reports/DashboardView/Property/history")
        ),
      },
      {
        exact: true,
        path: "/app/reports/dashboard/property/soa",
        component: lazy(() =>
          import("src/views/reports/DashboardView/Property/soa")
        ),
      },
      {
        exact: true,
        path: "/app/paynamics/payment/successfull/:userid/:amount",
        component: lazy(() =>
          import(
            "src/views/reports/DashboardView/PaymentPaynamics/PaynamicsPaymentSuccess"
          )
        ),
      },
      {
        exact: true,
        path: "/app/reports/dashboard-alternative",
        component: lazy(() =>
          import("src/views/reports/DashboardAlternativeView")
        ),
      },
      {
        exact: true,
        path: "/app/reports",
        component: () => <Redirect to="/app/reports/dashboard" />,
      },

      {
        exact: true,
        path: "/app/calendar",
        component: lazy(() => import("src/views/calendar/CalendarView")),
      },

      {
        exact: true,
        path: "/app/extra/charts/apex",
        component: lazy(() => import("src/views/extra/charts/ApexChartsView")),
      },

      {
        exact: true,
        path: "/app/business/registration",
        component: lazy(() =>
          import(
            "src/views/reports/DashboardView/EnrollmentRequest/EnrollBusToUser"
          )
        ),
      },

      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  // lcrs
  {
    path: "/lcrs",
    layout: DashboardLayout,
    guard: LCRSGuard,
    routes: [
      {
        exact: true,
        path: "/lcrs",
        component: () => <Redirect to="/lcrs/dashboard" />,
      },
      {
        exact: true,
        path: "/lcrs/reports",
        component: lazy(() => import("src/views/lcrs/reports")),
      },
      {
        exact: true,
        path: "/lcrs/search",
        component: lazy(() => import("src/views/lcrs/search")),
      },

      {
        exact: true,
        path: "/lcrs/forms",
        component: lazy(() => import("src/views/lcrs/forms")),
      },
      {
        exact: true,
        path: "/lcrs/request",
        component: lazy(() => import("src/views/lcrs/request")),
      },
      {
        exact: true,
        path: "/lcrs/digitization",
        component: lazy(() => import("src/views/lcrs/digitization")),
      },
      {
        exact: true,
        path: "/lcrs/digitization-search",
        component: lazy(() => import("src/views/lcrs/digitization-search")),
      },
      {
        exact: true,
        path: "/lcrs/review",
        component: lazy(() => import("src/views/lcrs/review")),
      },
      {
        exact: true,
        path: "/lcrs/approval",
        component: lazy(() => import("src/views/lcrs/approval")),
      },
      {
        exact: true,
        path: "/lcrs/payment",
        component: lazy(() => import("src/views/lcrs/payment")),
      },
      {
        exact: true,
        path: "/lcrs/personnel",
        component: lazy(() => import("src/views/lcrs/personnel")),
      },
      {
        exact: true,
        path: "/lcrs/status",
        component: lazy(() => import("src/views/lcrs/status")),
      },
      {
        exact: true,
        path: "/lcrs/request-reports",
        component: lazy(() => import("src/views/lcrs/request-reports")),
      },
      {
        exact: true,
        path: "/lcrs/request-search",
        component: lazy(() => import("src/views/lcrs/request-search")),
      },
      {
        exact: true,
        path: "/lcrs/request-status",
        component: lazy(() => import("src/views/lcrs/request-status")),
      },
      {
        exact: true,
        path: "/lcrs/request-review",
        component: lazy(() => import("src/views/lcrs/request-review")),
      },
      {
        exact: true,
        path: "/lcrs/request-approval",
        component: lazy(() => import("src/views/lcrs/request-approval")),
      },
      {
        exact: true,
        path: "/lcrs/request-payment",
        component: lazy(() => import("src/views/lcrs/request-payment")),
      },
      {
        exact: true,
        path: "/lcrs/users",
        component: lazy(() => import("src/views/lcrs/users")),
      },
      {
        exact: true,
        path: "/lcrs/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
      {
        exact: true,
        path: "/lcrs/print/:id/:type/:application_id",
        component: lazy(() => import("src/views/lcrs/reports/LCRSPrintLayout")),
      },
      {
        exact: true,
        path:
          "/lcrs/approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() => import("src/views/lcrs/approval/LCRSApproval")),
      },
      {
        exact: true,
        path:
          "/lcrs/request-approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-approval/LCRSRequestApproval")
        ),
      },
      {
        exact: true,
        path: "/lcrs/request-status/:type/:application_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-status/LCRSRequestStatusCheck")
        ),
      },
      {
        exact: true,
        path: "/lcrs/status/:type/:application_id/:service_id",
        component: lazy(() => import("src/views/lcrs/status/LCRSStatusCheck")),
      },
    ],
  },

  // approver
  {
    path: "/approver",
    layout: DashboardLayout,
    guard: ApproverGuard,
    routes: [
      {
        exact: true,
        path: "/approver",
        component: () => <Redirect to="/approver/for-approval" />,
      },

      {
        exact: true,
        path: "/approver/for-approval",
        component: lazy(() => import("src/views/lcrs/approval")),
      },

      {
        exact: true,
        path: "/approver/for-release",
        component: lazy(() => import("src/views/lcrs/reports")),
      },

      {
        exact: true,
        path: "/approver/status",
        component: lazy(() => import("src/views/lcrs/status")),
      },

      {
        exact: true,
        path: "/approver/request-reports",
        component: lazy(() => import("src/views/lcrs/request-reports")),
      },

      {
        exact: true,
        path: "/approver/search",
        component: lazy(() => import("src/views/lcrs/search")),
      },

      {
        exact: true,
        path: "/approver/request-status",
        component: lazy(() => import("src/views/lcrs/request-status")),
      },

      {
        exact: true,
        path: "/approver/request-approval",
        component: lazy(() => import("src/views/lcrs/request-approval")),
      },

      {
        exact: true,
        path: "/approver/request-status/:type/:application_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-status/LCRSRequestStatusCheck")
        ),
      },

      {
        exact: true,
        path: "/approver/reports",
        component: lazy(() => import("src/views/lcrs/reports")),
      },

      {
        exact: true,
        path: "/approver/request-search",
        component: lazy(() => import("src/views/lcrs/request-search")),
      },

      {
        exact: true,
        path: "/approver/digitization-search",
        component: lazy(() => import("src/views/lcrs/digitization-search")),
      },

      {
        exact: true,
        path:
          "/approver/approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() => import("src/views/lcrs/approval/LCRSApproval")),
      },

      {
        exact: true,
        path:
          "/approver/request-approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-approval/LCRSRequestApproval")
        ),
      },

      {
        exact: true,
        path: "/approver/print/:id/:type/:application_id",
        component: lazy(() => import("src/views/lcrs/reports/LCRSPrintLayout")),
      },

      {
        exact: true,
        path: "/approver/status/:type/:application_id/:service_id",
        component: lazy(() => import("src/views/lcrs/status/LCRSStatusCheck")),
      },

      {
        exact: true,
        path: "/approver/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
    ],
  },

  // approver-reviewer
  {
    path: "/approver-reviewer",
    layout: DashboardLayout,
    guard: ReviewerGuard,
    routes: [
      {
        exact: true,
        path: "/approver-reviewer",
        component: () => <Redirect to="/approver-reviewer/for-review" />,
      },

      {
        exact: true,
        path: "/approver-reviewer/for-review",
        component: lazy(() => import("src/views/lcrs/review")),
      },

      {
        exact: true,
        path: "/approver-reviewer/status",
        component: lazy(() => import("src/views/lcrs/status")),
      },

      {
        exact: true,
        path: "/approver-reviewer/search",
        component: lazy(() => import("src/views/lcrs/search")),
      },

      {
        exact: true,
        path: "/approver-reviewer/request-review",
        component: lazy(() => import("src/views/lcrs/request-review")),
      },

      {
        exact: true,
        path: "/approver-reviewer/request-status",
        component: lazy(() => import("src/views/lcrs/request-status")),
      },

      {
        exact: true,
        path: "/approver-reviewer/request-search",
        component: lazy(() => import("src/views/lcrs/request-search")),
      },

      {
        exact: true,
        path: "/approver-reviewer/digitization-search",
        component: lazy(() => import("src/views/lcrs/digitization-search")),
      },

      {
        exact: true,
        path:
          "/approver-reviewer/approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() => import("src/views/lcrs/approval/LCRSApproval")),
      },

      {
        exact: true,
        path:
          "/approver-reviewer/request-approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-approval/LCRSRequestApproval")
        ),
      },
      {
        exact: true,
        path:
          "/approver-reviewer/request-status/:type/:application_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-status/LCRSRequestStatusCheck")
        ),
      },

      {
        exact: true,
        path: "/approver-reviewer/status/:type/:application_id/:service_id",
        component: lazy(() => import("src/views/lcrs/status/LCRSStatusCheck")),
      },

      {
        exact: true,
        path: "/approver-reviewer/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
    ],
  },

  // treasury
  {
    path: "/treasury",
    layout: DashboardLayout,
    guard: TreasuryGuard,
    routes: [
      {
        exact: true,
        path: "/treasury",
        component: () => <Redirect to="/treasury/for-payment" />,
      },

      {
        exact: true,
        path: "/treasury/for-payment",
        component: lazy(() => import("src/views/lcrs/payment")),
      },

      {
        exact: true,
        path: "/treasury/status",
        component: lazy(() => import("src/views/lcrs/status")),
      },

      {
        exact: true,
        path: "/treasury/search",
        component: lazy(() => import("src/views/lcrs/search")),
      },

      {
        exact: true,
        path: "/treasury/request-payment",
        component: lazy(() => import("src/views/lcrs/request-payment")),
      },

      {
        exact: true,
        path: "/treasury/request-status",
        component: lazy(() => import("src/views/lcrs/request-status")),
      },

      {
        exact: true,
        path: "/treasury/request-search",
        component: lazy(() => import("src/views/lcrs/request-search")),
      },

      {
        exact: true,
        path: "/treasury/digitization-search",
        component: lazy(() => import("src/views/lcrs/digitization-search")),
      },

      {
        exact: true,
        path:
          "/treasury/approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() => import("src/views/lcrs/approval/LCRSApproval")),
      },

      {
        exact: true,
        path:
          "/treasury/request-approval/:type/:application_id/:status/:list_status_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-approval/LCRSRequestApproval")
        ),
      },

      {
        exact: true,
        path: "/treasury/request-status/:type/:application_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-status/LCRSRequestStatusCheck")
        ),
      },

      {
        exact: true,
        path: "/treasury/status/:type/:application_id/:service_id",
        component: lazy(() => import("src/views/lcrs/status/LCRSStatusCheck")),
      },

      {
        exact: true,
        path: "/treasury/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
    ],
  },

  // user-inhouse
  {
    path: "/user-inhouse",
    layout: DashboardLayout,
    guard: UserInHouseGuard,
    routes: [
      {
        exact: true,
        path: "/user-inhouse",
        component: () => <Redirect to="/user-inhouse/for-application" />,
      },

      {
        exact: true,
        path: "/user-inhouse/for-application",
        component: lazy(() => import("src/views/lcrs/forms")),
      },

      {
        exact: true,
        path: "/user-inhouse/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
      {
        exact: true,
        path: "/user-inhouse/forms",
        component: lazy(() => import("src/views/lcrs/forms")),
      },

      {
        exact: true,
        path: "/user-inhouse/status",
        component: lazy(() => import("src/views/lcrs/status")),
      },
      {
        exact: true,
        path: "/user-inhouse/request",
        component: lazy(() => import("src/views/lcrs/request")),
      },
      {
        exact: true,
        path: "/user-inhouse/request-status",
        component: lazy(() => import("src/views/lcrs/request-status")),
      },
      {
        exact: true,
        path: "/user-inhouse/digitization",
        component: lazy(() => import("src/views/lcrs/digitization")),
      },

      {
        exact: true,
        path: "/user-inhouse/digitization-search",
        component: lazy(() => import("src/views/lcrs/digitization-search")),
      },

      {
        exact: true,
        path: "/user-inhouse/status/:type/:application_id/:service_id",
        component: lazy(() => import("src/views/lcrs/status/LCRSStatusCheck")),
      },

      {
        exact: true,
        path: "/user-inhouse/request-status/:type/:application_id/:service_id",
        component: lazy(() =>
          import("src/views/lcrs/request-status/LCRSRequestStatusCheck")
        ),
      },
    ],
  },

  // administrator
  {
    path: "/administrator",
    layout: DashboardLayout,
    guard: AdminGuard,
    routes: [
      {
        exact: true,
        path: "/administrator",
        component: () => <Redirect to="/administrator/dashboard" />,
      },
      {
        exact: true,
        path: "/administrator/users",
        component: lazy(() => import("src/views/reports/DashboardAdmin/Users")),
      },
      {
        exact: true,
        path: "/administrator/dashboard",
        component: lazy(() => import("src/views/reports/DashboardAdmin")),
      },
      {
        exact: true,
        path: "/administrator/email/sent",
        component: lazy(() => import("src/views/reports/DashboardAdmin/Email")),
      },
      {
        exact: true,
        path: "/administrator/account",
        component: lazy(() => import("src/views/pages/AccountView")),
      },
      {
        exact: true,
        path: "/administrator/reports/payments",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/paynamics")
        ),
      },

      // admin lcrs
      {
        exact: true,
        path: "/administrator/lcrs/death-cause",
        component: lazy(() => import("src/views/lcrs/forms/death/cause")),
      },

      {
        exact: true,
        path: "/administrator/lcrs/birth-type",
        component: lazy(() => import("src/views/lcrs/forms/birth/type")),
      },

      {
        exact: true,
        path: "/administrator/lcrs/birth-order",
        component: lazy(() => import("src/views/lcrs/forms/birth/order")),
      },

      // olapp routes

      {
        exact: true,
        path: "/administrator/olapp/services",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/services")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/services/steps/:serviceId",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/services/steps")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/services/downloadable-forms/:serviceId",
        component: lazy(() =>
          import(
            "src/views/reports/DashboardAdmin/Olapp/services/downloadable-forms"
          )
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/services/fees/:serviceId",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/services/fees")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/announcements",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/announcements")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/requirements",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/requrements")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/requirements/steps",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/requirements-steps")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/requirements/list",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/requrements/items")
        ),
      },

      {
        exact: true,
        path: "/administrator/olapp/downloadable-forms",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Olapp/downloadable-forms")
        ),
      },

      {
        exact: true,
        path: "/administrator/report/paynamics/payment",
        component: lazy(() =>
          import("src/views/reports/DashboardAdmin/Report/paynamics")
        ),
      },

      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  {
    path: "/registrator",
    layout: DashboardLayout,
    guard: RegistratorGuard,
    routes: [
      {
        exact: true,
        path: "/registrator",
        component: () => <Redirect to="/registrator/dashboard" />,
      },
      {
        exact: true,
        path: "/registrator/dashboard",
        component: lazy(() => import("src/views/reports/Registrator")),
      },
    ],
  },

  // docs
  {
    path: "/docs",
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: "/docs",
        component: () => <Redirect to="/docs/welcome" />,
      },
      {
        exact: true,
        path: "/docs/welcome",
        component: lazy(() => import("src/views/docs/WelcomeView")),
      },
      {
        exact: true,
        path: "/docs/getting-started",
        component: lazy(() => import("src/views/docs/GettingStartedView")),
      },
      {
        exact: true,
        path: "/docs/environment-variables",
        component: lazy(() =>
          import("src/views/docs/EnvironmentVariablesView")
        ),
      },
      {
        exact: true,
        path: "/docs/deployment",
        component: lazy(() => import("src/views/docs/DeploymentView")),
      },
      {
        exact: true,
        path: "/docs/api-calls",
        component: lazy(() => import("src/views/docs/ApiCallsView")),
      },
      {
        exact: true,
        path: "/docs/analytics",
        component: lazy(() => import("src/views/docs/AnalyticsView")),
      },
      {
        exact: true,
        path: "/docs/authentication",
        component: lazy(() => import("src/views/docs/AuthenticationView")),
      },
      {
        exact: true,
        path: "/docs/routing",
        component: lazy(() => import("src/views/docs/RoutingView")),
      },
      {
        exact: true,
        path: "/docs/settings",
        component: lazy(() => import("src/views/docs/SettingsView")),
      },
      {
        exact: true,
        path: "/docs/state-management",
        component: lazy(() => import("src/views/docs/StateManagementView")),
      },
      {
        exact: true,
        path: "/docs/theming",
        component: lazy(() => import("src/views/docs/ThemingView")),
      },
      {
        exact: true,
        path: "/docs/support",
        component: lazy(() => import("src/views/docs/SupportView")),
      },
      {
        exact: true,
        path: "/docs/changelog",
        component: lazy(() => import("src/views/docs/ChangelogView")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  // all
  {
    path: "*",
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/home",
        component: HomeView,
      },
      {
        exact: true,
        path: "/home/guide",
        component: Guide,
      },
      {
        exact: true,
        path: "/pricing",
        component: lazy(() => import("src/views/pages/PricingView")),
      },
      {
        exact: true,
        path: "/home/terms-and-conditions",
        component: lazy(() => import("src/views/pages/TermsAndConditions")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
