import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

function ReviewerGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === "user") {
    return <Redirect to="/app" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === "registrator"
  ) {
    return <Redirect to="/registrator" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === "admin") {
    return <Redirect to="/administrator" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === "registrar"
  ) {
    return <Redirect to="/approver" />;
  }

  //   if (
  //     account.user.roles.length > 0 &&
  //     account.user.roles[0].name === "registrar-reviewer"
  //   ) {
  //     return <Redirect to="/approver-reviewer" />;
  //   }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === "treasury"
  ) {
    return <Redirect to="/treasury" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === "user-inhouse"
  ) {
    return <Redirect to="/user-inhouse" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === "lcrs-encoder"
  ) {
    return <Redirect to="/lcrs" />;
  }

  return children;
}

ReviewerGuard.propTypes = {
  children: PropTypes.any,
};

export default ReviewerGuard;
