// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import {
  Calendar as CalendarIcon,
  Folder as FolderIcon,
  Edit as EditIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Users,
  List as ListAltIcon,
  Folder,
  AlignLeft,
  UserCheck,
  AlignRight,
  Search,
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import { ListAlt } from "@material-ui/icons";

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        enabled={item.enabled}
        style={{
          display:
            item.currentrole === "admin"
              ? "block"
              : item.owner === item.currentrole
              ? // item.currentrole !== 'user-inhouse' ?
                "block"
              : // : "none"
              item.owner === "all"
              ? "block"
              : "none",
        }}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        enabled={item.enabled}
      />
    );
  }

  return acc;
}

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  const navConfig = [
    /** user dashboard **/
    {
      subheader: "",
      owner: "user",
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/app/reports/dashboard",
          enabled: true,
        },
      ],
    },

    /** user dashboard **/
    {
      subheader: "",
      owner: "registrator",
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/registrator/dashboard",
          enabled: true,
        },
      ],
    },

    // user
    {
      subheader: "Reports",
      owner: "user",
      items: [
        {
          title: "Property Holdings",
          icon: ListAltIcon,
          href: "/app/reports/dashboard/property/holdings",
          enabled: true,
        },
        {
          title: "Payment History",
          icon: ListAltIcon,
          href: "/app/reports/dashboard/property/history",
          enabled: true,
        },
        {
          title: "Statement of Account",

          icon: ListAltIcon,
          href: "/app/reports/dashboard/property/soa",
          enabled: true,
        },
      ],
    },

    /** admin dashboard **/
    {
      subheader: "",
      owner: "admin",
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/administrator/dashboard",
          enabled: true,
        },
      ],
    },

    // admin
    {
      subheader: "Report",
      owner: "admin",
      items: [
        {
          title: "Payments",
          icon: ListAlt,
          href: "/administrator/reports/payments",
          enabled: true,
        },
      ],
    },

    // admin
    {
      subheader: "Users",
      owner: "admin",
      items: [
        {
          title: "List",
          icon: Users,
          href: "/administrator/users",
          enabled: true,
        },
      ],
    },

    // admin
    {
      subheader: "LCRS",
      owner: "admin",
      items: [
        {
          title: "Death Cause",
          icon: ListAltIcon,
          href: "/administrator/lcrs/death-cause",
          enabled: true,
        },

        {
          title: "Birth Type",
          icon: ListAltIcon,
          href: "/administrator/lcrs/birth-type",
          enabled: true,
        },

        {
          title: "Birth Order",
          icon: ListAltIcon,
          href: "/administrator/lcrs/birth-order",
          enabled: true,
        },
      ],
    },

    /** emd admin dashboard **/

    //lcrs-encoder
    {
      subheader: "Dashboard",
      owner: "lcrs-encoder",
      items: [
        {
          title: "LCR Reports",
          icon: ListAltIcon,
          href: "/lcrs/reports",
          enabled: true,
          currentrole: user.roles[0].name,
        },
        {
          title: "LCR Forms",
          icon: ListAltIcon,
          href: "/lcrs/forms",
          enabled: true,
          currentrole: user.roles[0].name,
        },
        {
          title: "Personnel",
          icon: ListAltIcon,
          href: "/lcrs/personnel",
          enabled: true,
          currentrole: user.roles[0].name,
        },
      ],
    },

    //Olapp
    {
      subheader: "Olapp",
      owner: "admin",
      items: [
        {
          title: "Services",
          icon: ListAltIcon,
          href: "/administrator/olapp/services",
          enabled: true,
        },

        {
          title: "Requirements Group",
          icon: ListAltIcon,
          href: "/administrator/olapp/requirements",
          enabled: true,
        },

        {
          title: "Requirements List",
          icon: ListAltIcon,
          href: "/administrator/olapp/requirements/list",
          enabled: true,
        },

        {
          title: "Announcements",
          icon: ListAltIcon,
          href: "/administrator/olapp/announcements",
          enabled: true,
        },
      ],
    },

    // lcrs
    {
      subheader: "LCRS",
      items: [
        {
          title: "Users",
          icon: Users,
          href: "/lcrs/users",
          enabled: true,
          owner: "admin",
          currentrole: user.roles[0].name,
        },
        {
          title: "For Release",
          icon: Folder,
          href: "/lcrs/reports",
          enabled: true,
          owner: "registrar",
          currentrole: user.roles[0].name,
        },
        {
          title: "For Application",
          icon: AlignLeft,
          href: "/lcrs/forms",
          enabled: true,
          owner: "user-inhouse",
          currentrole: user.roles[0].name,
        },
        {
          title: "For Review",
          icon: AlignLeft,
          href: "/lcrs/review",
          enabled: true,
          owner: "registrar-reviewer",
          currentrole: user.roles[0].name,
        },
        {
          title: "For Approval",
          icon: AlignLeft,
          href: "/lcrs/approval",
          enabled: true,
          owner: "registrar",
          currentrole: user.roles[0].name,
        },
        {
          title: "For Payment",
          icon: AlignLeft,
          href: "/lcrs/payment",
          enabled: true,
          owner: "treasurer",
          currentrole: user.roles[0].name,
        },
        {
          title: "Personnel",
          icon: UserCheck,
          href: "/lcrs/personnel",
          enabled: true,
          owner: "admin",
          currentrole: user.roles[0].name,
        },
      ],
    },

    // approver
    // {
    //   subheader: "Dashboard",
    //   owner: "registrar",
    //   items: [
    //     {
    //       title: "For Approval",
    //       icon: AlignRight,
    //       href: "/approver/for-approval",
    //       enabled: true,
    //     },

    //     {
    //       title: "For Release",
    //       icon: AlignRight,
    //       href: "/approver/for-release",
    //       enabled: true,
    //     },
    //   ],
    // },

    //for approver view
    {
      subheader: "Registration",
      owner: "registrar",
      items: [
        {
          title: "For Approval",
          icon: AlignLeft,
          href: "/approver/for-approval",
          enabled: true,
        },
        {
          title: "For Release",
          icon: AlignLeft,
          href: "/approver/reports",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/approver/status",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/approver/search",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Request",
      owner: "registrar",
      items: [
        {
          title: "For Approval",
          icon: AlignLeft,
          href: "/approver/request-approval",
          enabled: true,
        },
        {
          title: "For Release",
          icon: AlignLeft,
          href: "/approver/request-reports",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/approver/request-status",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/approver/request-search",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Digitize",
      owner: "registrar",
      items: [
        {
          title: "Search",
          icon: Search,
          href: "/approver/digitization-search",
          enabled: true,
        },
      ],
    },

    // registrar end

    // registrar-reviewer
    {
      subheader: "Registration",
      owner: "registrar-reviewer",
      items: [
        {
          title: "For Review",
          icon: AlignLeft,
          href: "/approver-reviewer/for-review",
          enabled: true,
        },

        {
          title: "Check Status",
          icon: UserCheck,
          href: "/approver-reviewer/status",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/approver-reviewer/search",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Request",
      owner: "registrar-reviewer",
      items: [
        {
          title: "For Review",
          icon: AlignLeft,
          href: "/approver-reviewer/request-review",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/approver-reviewer/request-status",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/approver-reviewer/request-search",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Digitize",
      owner: "registrar-reviewer",
      items: [
        {
          title: "Search",
          icon: Search,
          href: "/approver-reviewer/digitization-search",
          enabled: true,
        },
      ],
    },

    //registrar-reviewer end

    //for treasurer view
    {
      subheader: "Registration",
      owner: "treasury",
      items: [
        {
          title: "For Payment",
          icon: AlignLeft,
          href: "/treasury/for-payment",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/treasury/status",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/treasury/search",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Request",
      owner: "treasury",
      items: [
        {
          title: "For Payment",
          icon: AlignLeft,
          href: "/treasury/request-payment",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/treasury/request-status",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/treasury/request-search",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Digitize",
      owner: "treasury",
      items: [
        {
          title: "Search",
          icon: Search,
          href: "/treasury/digitization-search",
          enabled: true,
        },
      ],
    },

    // treasury end

    // user-inhouse
    {
      subheader: "Registration",
      owner: "user-inhouse",
      items: [
        {
          title: "Form",
          icon: AlignLeft,
          href: "/user-inhouse/forms",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/user-inhouse/status",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Request",
      owner: "user-inhouse",
      items: [
        {
          title: "Form",
          icon: AlignLeft,
          href: "/user-inhouse/request",
          enabled: true,
        },
        {
          title: "Check Status",
          icon: UserCheck,
          href: "/user-inhouse/request-status",
          enabled: true,
        },
      ],
    },
    {
      subheader: "Digitize",
      owner: "user-inhouse",
      items: [
        {
          title: "Form",
          icon: AlignLeft,
          href: "/user-inhouse/digitization",
          enabled: true,
        },
        {
          title: "Search",
          icon: Search,
          href: "/user-inhouse/digitization-search",
          enabled: true,
        },
      ],
    },
    //user-inhouse end

    // Documents
    {
      subheader: "Documents",
      owner: user.roles[0].name,
      items: [
        {
          title: "FAAS",
          icon: EditIcon,
          href: "/app/management/faas",
          enabled: false,
        },
        {
          title: "Tax Declaration",
          icon: ReceiptIcon,
          href: "/app/management/products",
          enabled: false,
        },
        {
          title: "Certificates",
          icon: FolderIcon,
          href: "/app/management/certificates",
          enabled: false,
          items: [
            {
              title: "Witholding Tax Cert",
              href: "/app/management/taxcert",
              enabled: false,
            },
            {
              title: "Land Holdings",
              href: "/app/management/landholdings/1",
              enabled: false,
            },
          ],
        },
      ],
    },

    // Applications
    {
      subheader: "Applications",
      items: [
        {
          title: "Mail",
          href: "/app/mail",
          icon: MailIcon,
          enabled: false,
          info: () => <Chip color="secondary" size="small" label="Updated" />,
        },
        {
          title: "Chat",
          href: "/app/chat",
          icon: MessageCircleIcon,
          enabled: false,
          info: () => <Chip color="secondary" size="small" label="Updated" />,
        },
        {
          title: "Calendar",
          href: "/app/calendar",
          icon: CalendarIcon,
          enabled: false,
        },
      ],
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig
            .filter((x) => x.owner === user.roles[0].name.trim())
            .map((config, index) => (
              <List
                key={index}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
        </Box>
        <Divider />
        <Box p={1}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textSecondary" align="center">
              To register your property? Click{" "}
              {/* <a
                href="mailto:register.rpu.marilao@gmail.com?subject=Request for Real Property Registration&body=Hi,
                      I would like to request the attached file(s) for the registration on eRPT Online Payment App."
              >
                register.rpu.marilao@gmail.com
              </a> */}
            </Typography>

            <Box mt={1}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                component={RouterLink}
                to="/app/property/register"
                fullWidth
                disabled={user?.roles?.[0]?.name?.trim() !== "user"}
              >
                {" "}
                Register{" "}
              </Button>
            </Box>
            {/* <Typography variant="h6" color="textWarning">
              Please use your registered email account
            </Typography> */}
            {/* <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link> */}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
