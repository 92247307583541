import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { logout } from "src/actions/accountActions";

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const accountURL =
    account.user.roles &&
    account.user.roles.length &&
    account.user.roles[0].name === "admin"
      ? "administrator"
      : account.user.roles[0].name === "admin"
      ? "lcrs-encoder"
      : "app";
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push("/");
    } catch (error) {
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={`${imageLocation}avatar/${account.user.avatar}`}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${account.user.firstname} ${account.user.lastname}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem component={RouterLink} to="/app/social/profile">
          Profile
        </MenuItem> */}
        <MenuItem component={RouterLink} to={`/${accountURL}/account`}>
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
