import jwtDecode from 'jwt-decode';
import real_axios from 'axios'; 

const AUTH_URL = process.env.REACT_APP_AUTH;

class AuthService {
  setAxiosInterceptors = ({onLogout}) => {
    real_axios.interceptors.response.use (
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession (null);

          if (onLogout) {
            onLogout ();
          }
        }
        return Promise.reject (error);
      }
    );
  };

  handleAuthentication () {
    const accessToken = this.getAccessToken ();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken (accessToken)) {
      this.setSession (accessToken);
    } else {
      this.setSession (null);
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise ((resolve, reject) => { 
      real_axios
        .post (AUTH_URL + 'signin', {
          email, password
        })
        .then (response => {
          if (response.data.user) {
            this.setSession (response.data.accessToken); 
            resolve (response.data.user);
          } else {
            reject (response.data.error);
          }
        })
        .catch (error => {
          reject (error);
        });
    });

  //.get('/api/account/me')
  loginInWithToken = () =>
    new Promise ((resolve, reject) => { 
      real_axios
        .get ('/account/me')
        .then (response => { 
          if (response.data) { 
            resolve (response.data);
          } else {
            reject (response.data.error);
          }
        })
        .catch (error => {
          reject (error);
        });
    });

  logout = () => {
    this.setSession (null);
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem ('accessToken', accessToken);
      real_axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem ('accessToken');
      delete real_axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem ('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode (accessToken);
    const currentTime = Date.now () / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken ();
}

const authService = new AuthService ();

export default authService;
