import axios from 'axios';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const REGISTER_FAILURE = '@account/register-failure';
export const REGISTER_SUCCESS = '@account/register-success';

export const PENDING_PAYMENTS = '@account/pending-payments';

export const UPDATE_PROFILE = '@account/update-profile';

export function register(newUser) {
  return async dispatch => {
    try {
      const request = await axios.post(`auth/signup`, newUser);

      dispatch({
        type: REGISTER,
        payload: {
          request
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const user = await authService.loginWithEmailAndPassword(email, password);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return async dispatch => {
    authService.logout();
    //localStorage.removeItem('user');
    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(update) {
  return async dispatch => {
    try {
      const request = axios.post(`/account/update-profile`, update);
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          request
        }
      });
    } catch (error) {
      // console.log (error);
      throw error;
    }
  };
}

export function pendingPayments(params) {
  return async dispatch => {
    try {
      const request = await axios.get(
        `/test/user/checkout/with-pendindstatus`,
        params
      );

      dispatch({
        type: PENDING_PAYMENTS,
        payload: {
          pending_payments: request.data
        }
      });
    } catch (error) {
      // console.log (error);
      throw error;
    }
  };
}
