import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down("md")]: {
      paddingTop: 60,
      paddingBottom: 60,
    },
  },
  image: {
    perspectiveOrigin: "left center",
    transformStyle: "preserve-3d",
    perspective: 1500,
    "& > img": {
      maxWidth: "90%",
      height: "auto",
      transform: "rotateY(-35deg) rotateX(15deg)",
      backfaceVisibility: "hidden",
      boxShadow: theme.shadows[16],
    },
  },
  shape: {
    position: "absolute",
    top: 0,
    left: 0,
    "& > img": {
      maxWidth: "90%",
      height: "auto",
    },
  },
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="overline" color="textSecondary">
                Introducing
              </Typography>
              <Typography variant="h1" color="textPrimary">
                eRPT Online Payment
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  {/* eRPT is an information management tool for Real Property
                    Owners that tracks, gathers, and displays property data in
                    interactive and visualizations enable to monitor tax dues,
                    payment timeline, property holdings and provide with
                    actionable insights like make payments online. */}
                  Isang paraan ng pagbabayad ng buwis gamit ang smartphone at
                  internet.{" "}
                </Typography>
                <Box mt={3} />
                <Box />
                <Typography variant="body1" color="textSecondary">
                  {" "}
                  Sana po ang lahat ng mamamayan sa Bayan ng Tiwi ay makiisa,
                  para ang bayan natin ay umunlad, maging matahimik at maging
                  maayos.{" "}
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item>
                    {/* <Typography variant="h4" color="textPrimary">
                      Ramdam na Serbisyo, para sa Marilenyo.
                    </Typography> */}

                    <Box mt={4} align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("home/guide")}
                      >
                        Paano Magbayad at Magparehistro
                      </Button>
                    </Box>
                  </Grid>

                  {/* <Grid item>
                    <Typography variant="h3" color="secondary">
                      eFficient
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Result
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" color="secondary">
                      cost Effective
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Application
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              position="relative"
              style={{
                textAlign: "center",
              }}
            >
              <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/dark-light.png"
                  width={"400px"}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
